// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useRef, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Field, Form, Formik } from "formik";
import { DatePickerField, Input, Select } from "../../../../../../../_metronic/_partials/controls";
import { SUBCONTRACTOR } from "../../../../../../_utils/userTypes";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../../PropertiesManagement/_redux/projects/projectsActions";
import * as budgetActions from "../../../../../Budget/_redux/budgetsActions";
import { Link } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { canEdit, canReadAll, canReadAllUserDocument } from "../../../../../../_utils/authUtils";
import { INVOICE_STATUS, USER_FILE_TYPES } from "../../../../../../_utils/listUtils";
import { isBudgetServiceAvailable } from "../../../../../../_utils/configUtils";
import { Checkbox } from "../../../../../../_components/Checkbox";
import {
  FinanceFileType,
  isFinanceFile,
  isReInvoiceDetailPriceLine,
  UserType,
} from "../../../../../../../data/schemas";
import { FileDropzone } from "../../../../../../_components/FileDropzone";
import { LinkedFiles } from "../../../../../../_components/LinkedFiles";
import { formatDisplayName } from "../../../../../../_utils/userUtils";
import { UserFileFinanceCategories } from "./finance/UserFileFinanceCategories";
import { useUserFilesUIContext } from "../UserFilesUIContext";
import { UserFileFinanceReInvoice } from "./finance/UserFileFinanceReInvoice";
import { createPortal } from "react-dom";
import { UserFileFinanceTotals } from "./finance/UserFileFinanceTotals";
import { cloneDeep, isEqual, sumBy } from "lodash-es";
import { useConfirmationDialogContext } from "../../../../../../_components/ConfirmationDialog";
import { UserFileFormValidationSchema } from "./UserFileFormValidationSchema";
import { v4 as uuid } from "uuid";
import { Notification } from "../../../../../../_components/Notification";
import { TagManager } from "../../../../../../_components/TagManager";
import { FILE, TAG } from "../../../../../../_utils/dataTypes";
import TitleWithGuideLink from "app/_components/TitleWithGuideLink";
import { useTranslation } from "app/hooks/useTranslation";
import { DateUtils } from "../../../../../../_utils/DateUtils";
import { NumberInput } from "../../../../../../_utils/formUtils";
import { SUFFIX_EURO_CURRENCY } from "../../../../../../_utils/suffixUtils";

export function UserFileEditForm({
  saveFile,
  saveMultipleFiles,
  file,
  actionsLoading,
  onHide,
  isUploadingFile,
  readOnly,
  userTypes,
}) {
  const intl = useIntl();
  const { fm } = useTranslation();
  const { financeFileDefaultCategory, footerRef, userId } = useUserFilesUIContext();
  const { setConfirmationDialog } = useConfirmationDialogContext();
  const dispatch = useDispatch();

  const [selectedFileType, setSelectedFileType] = useState(file?.fileType ?? null);
  const [rectoVerso, setRectoVerso] = useState(false);
  const [project, setProject] = useState();
  const formRef = useRef(null);
  const isBudgetAvailable = React.useMemo(() => isBudgetServiceAvailable(), []);
  const isRequest = !isUploadingFile && !readOnly && !file.id;

  const { projectsListLoading, projects, budget, session, groups, entityForEdit, user } =
    useSelector(
      (state) => ({
        projectsListLoading: state.projects.listLoading,
        projects: state.projects.entities,
        budgetLoading: state.budgets.actionsLoading,
        budget: state.budgets?.budgetForEdit?.saved,
        session: state.auth.session,
        groups: state.auth.groups,
        entityForEdit: state.users.entityForEdit.current
          ? state.users.entityForEdit.current
          : state?.users?.entities?.find((entity) => entity?.id === file.userId),
        user: state.users.entityForEdit?.saved,
      }),
      shallowEqual
    );

  useEffect(() => {
    if (
      session &&
      groups &&
      !project &&
      canEdit(groups, session, "SUBCONTRACTOR") &&
      formRef?.current?.values?.userType === SUBCONTRACTOR
    ) {
      dispatch(actions.fetchProjects()).then((projects) => {
        if (file.projectId) {
          setProject(projects.find((project) => project.id === file.projectId));
        } else {
          setProject(projects[0]);
        }
      });
    }
  }, [session, groups, formRef?.current?.values?.userType]);

  useEffect(() => {
    if (isBudgetAvailable && isFinanceFile(formRef.current.values.fileType)) {
      dispatch(budgetActions.fetchBudget(project?.selectedBudget));
    }
  }, [project]);

  const [fileTypes, setFileTypes] = useState({});

  useEffect(() => {
    setFileTypes(USER_FILE_TYPES[formRef?.current?.values?.userType]);
  }, [formRef?.current?.values?.userType]);

  const onProjectChange = (e, handleChange, setFieldValue) => {
    handleChange(e);
    if (project && e.target.value !== project.id) {
      setFieldValue("categories", [financeFileDefaultCategory()]);
    }
    setProject(projects.find((project) => project.id === e.target.value));
  };

  const onUploadAsChange = (e, handleChange) => {
    handleChange(e);
    setFileTypes(USER_FILE_TYPES[e.target.value]);
  };

  const onFileTypeChange = (e, handleChange, setFieldValue, values) => {
    setSelectedFileType(e.target.value);
    handleChange(e);
    if (canEditSubcontractorFields(values)) {
      if (!values.categories) {
        setFieldValue("categories", [financeFileDefaultCategory()]);
      }
      if (!values.projectId) {
        setFieldValue("projectId", projects[0]?.id);
        setProject(projects[0]);
        if (isBudgetAvailable) {
          dispatch(budgetActions.fetchBudget(projects[0]?.selectedBudget));
        }
      } else {
        if (isBudgetAvailable) {
          dispatch(budgetActions.fetchBudget(project?.selectedBudget));
        }
      }
      if (e.target.value === "INVOICE") {
        if (!values.invoiceStatus) {
          setFieldValue("invoiceStatus", "TO_BE_PAID");
        }
      } else {
        setFieldValue("invoiceStatus", undefined);
      }
    }
  };

  const confirmReInvoiceChanges = (values) => {
    const changes = [];
    const forceNewFileCreationChanges = [];
    const currentTotalSubcontractor = sumBy(values.categories, ({ lines }) =>
      sumBy(lines, "amount")
    );
    const currentTotalReInvoice = sumBy(values.reInvoiceFile.details, (detail) =>
      isReInvoiceDetailPriceLine(detail)
        ? (detail.unitPrice ?? 0) * (values.reInvoiceFile?.useQuantities ? detail.quantity : 1)
        : 0
    );
    if (currentTotalSubcontractor !== currentTotalReInvoice) {
      changes.push(
        <span className={"text-danger"}>
          <FormattedMessage
            id={`FILE.REINVOICE_CHANGES.${
              currentTotalReInvoice > currentTotalSubcontractor ? "GREATER" : "SMALLER"
            }_TOTAL.${file.fileType}`}
          />
        </span>
      );
    }
    if (file.reInvoiceFile.fileName !== values.reInvoiceFile.fileName) {
      forceNewFileCreationChanges.push(
        <FormattedMessage id="FILE.REINVOICE_CHANGES.FILE_NAME_DIFF" />
      );
    }
    if (file.reInvoiceFile.useQuantities !== values.reInvoiceFile.useQuantities) {
      if (values.reInvoiceFile.useQuantities) {
        forceNewFileCreationChanges.push(
          <FormattedMessage id="FILE.REINVOICE_CHANGES.USE_QUANTITIES" />
        );
      } else {
        forceNewFileCreationChanges.push(
          <FormattedMessage id="FILE.REINVOICE_CHANGES.NOT_USE_QUANTITIES" />
        );
      }
    }
    const filteredFileDetails = file.reInvoiceFile.details.map(
      ({ chosen, selected, ...detail }) => detail
    );
    const filteredValuesDetails = values.reInvoiceFile.details.map(
      ({ chosen, selected, ...detail }) => detail
    );
    if (!isEqual(filteredFileDetails, filteredValuesDetails)) {
      forceNewFileCreationChanges.push(<FormattedMessage id={"FILE.REINVOICE_CHANGES.DETAILS"} />);
    }
    if (
      !!file.reInvoiceFile.includeFileToLeadFile !== !!values.reInvoiceFile.includeFileToLeadFile
    ) {
      if (values.reInvoiceFile.includeFileToLeadFile) {
        forceNewFileCreationChanges.push(
          <FormattedMessage id={`FILE.REINVOICE_CHANGES.FILE_ATTACHED.${file.fileType}`} />
        );
      } else {
        forceNewFileCreationChanges.push(
          <FormattedMessage id={`FILE.REINVOICE_CHANGES.FILE_DETACHED.${file.fileType}`} />
        );
      }
    }
    if (changes.length || forceNewFileCreationChanges.length) {
      setConfirmationDialog({
        action: {
          fn: () => saveForm(values, !!forceNewFileCreationChanges.length),
        },
        actionButtonLabel: intl.formatMessage({ id: "COMMON.ACTION.SAVE" }),
        actionButtonClassName: "btn-primary",
        title: intl.formatMessage({
          id: "FILE.ACTION.UPDATE_CLIENT_FILE",
        }),
        body: (
          <div>
            <ul className={"list-unstyled"}>
              {changes.map((change, index) => (
                <li key={index}>{change}</li>
              ))}
            </ul>
            {!!forceNewFileCreationChanges.length && (
              <span className={"font-weight-bold font-size-md mb-4"}>
                <FormattedMessage id="FILE.REINVOICE_CHANGES.NEW_FILE_WILL_BE_GENERATED" />
              </span>
            )}
            <ul>
              {forceNewFileCreationChanges.map((change, index) => (
                <li key={index}>{change}</li>
              ))}
            </ul>
          </div>
        ),
      });
    } else {
      saveForm(values);
    }
  };

  const saveForm = (values, forceNewFileCreation) => {
    values = { ...values, notifyUser: values?.notifyUser ?? [] };
    const filteredValues = cloneDeep(values);
    filteredValues.categories = values.categories?.map(({ chosen, selected, ...category }) => ({
      ...category,
      lines: category.lines.map(({ chosen, selected, ...line }) => line),
    }));
    if (!values.relatedEntity) {
      filteredValues.relatedEntity = { name: formatDisplayName(user, false) };
    }
    if (values.enableReInvoicing) {
      filteredValues.reInvoiceFile.details = filteredValues.reInvoiceFile.details.map(
        ({ chosen, selected, ...detail }) => detail
      );
      if (forceNewFileCreation) {
        filteredValues.reInvoiceFile.leadFileId = uuid();
      }
    } else if (!file.reInvoiceFile?.leadFileId) {
      // If reInvoiceFile was not already created and if the reInvoicing is disabled, remove the reInvoiceFile from object
      delete filteredValues.reInvoiceFile;
    }

    // TODO: merge these files in one PDF
    if (selectedFileType === "ID" && !rectoVerso && !!values.file && !!values.fileBack) {
      saveMultipleFiles(
        {
          ...filteredValues,
          friendlyName: "[ID] Recto",
        },
        {
          ...filteredValues,
          friendlyName: "[ID] Verso",
          file: values.fileBack,
        }
      );
    } else {
      saveFile(filteredValues);
    }
  };

  const disableFileTypeOption = (values, value) => {
    const isEditingFinanceFile =
      file.id && isFinanceFile(file.fileType) && !Object.values(FinanceFileType).includes(value);
    const isReInvoicing =
      values.enableReInvoicing &&
      ["QUOTE", "SUPPLEMENTARY_AGREEMENT"].includes(values.fileType) &&
      !["QUOTE", "SUPPLEMENTARY_AGREEMENT"].includes(value);
    return isEditingFinanceFile || isReInvoicing;
  };

  const canEditSubcontractorFields = (values) =>
    canEdit(groups, session, "SUBCONTRACTOR") &&
    (isUploadingFile || file.id) && // Check if not request document dialog
    values.userType === SUBCONTRACTOR;

  const canEditFinanceCategories = (values) =>
    canEditSubcontractorFields(values) && isFinanceFile(values.fileType);

  const canReInvoice = (values) =>
    canEdit(groups, session, "SUBCONTRACTOR") &&
    canEdit(groups, session, "BUDGET") &&
    canEdit(groups, session, "LEAD") &&
    ["SUPPLEMENTARY_AGREEMENT", "QUOTE"].includes(values.fileType) &&
    (isUploadingFile || file.id) &&
    values.userType === SUBCONTRACTOR;

  const uploadingFile = isUploadingFile && (selectedFileType || file.status === "REQUESTED");
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        ...file,
        ...(file.fileType === "INVOICE" && !file.invoiceStatus && file.userType === SUBCONTRACTOR
          ? { invoiceStatus: "TO_BE_PAID" }
          : {}),
        ...(uploadingFile ? { file: undefined, fileBack: undefined } : {}),
        isConfidential: isRequest ? false : file.isConfidential,
      }}
      validationSchema={UserFileFormValidationSchema({
        uploadingFile,
        uploadOrEdit: isUploadingFile || file.id,
        rectoVerso,
      })}
      onSubmit={(values) => {
        if (values.enableReInvoicing && file.id && file.enableReInvoicing) {
          confirmReInvoiceChanges(values);
        } else {
          saveForm(values);
        }
      }}
      innerRef={formRef}
    >
      {({ handleSubmit, setFieldValue, values, handleChange }) => (
        <>
          <Modal.Body className="overlay">
            <Form className="form form-label-right" data-cy="form-user-file">
              {canEditFinanceCategories(values) &&
                !!footerRef?.current &&
                createPortal(<UserFileFinanceTotals />, footerRef.current)}
              <div className="form-group row">
                <div className="col-lg-12">
                  <Field
                    data-cy="input-file-name"
                    name="friendlyName"
                    component={Input}
                    placeholder={intl.formatMessage({
                      id: "COMMON.NAME",
                    })}
                    label={intl.formatMessage({
                      id: "COMMON.NAME",
                    })}
                    disabled={readOnly}
                  />
                </div>
              </div>
              <div className="form-group row">
                {userTypes.length > 1 && ( // In case we are in profile
                  <div className="col-sm-6">
                    <Select
                      name="userType"
                      data-cy="select-file-upload-as"
                      label={intl.formatMessage({
                        id: "FILE.UPLOAD_AS",
                      })}
                      onChange={(e) => onUploadAsChange(e, handleChange)}
                    >
                      {userTypes.map((value) => (
                        <option key={value} value={value}>
                          {intl.formatMessage({ id: `${value}.TITLE.SINGLE` })}
                        </option>
                      ))}
                    </Select>
                  </div>
                )}
                <div className={userTypes.length > 1 ? "col-sm-6" : "col-12"}>
                  <Select
                    data-cy="select-file-type"
                    name="fileType"
                    label={intl.formatMessage({
                      id: "COMMON.TYPE",
                    })}
                    disabled={file.fileType === "FILLOUT_AML_KYC_FORM" || readOnly}
                    onChange={(e) => onFileTypeChange(e, handleChange, setFieldValue, values)}
                  >
                    {file.fileType === "FILLOUT_AML_KYC_FORM" ? (
                      <option>
                        {intl.formatMessage({
                          id: USER_FILE_TYPES.CLIENT.FILLOUT_AML_KYC_FORM,
                        })}
                      </option>
                    ) : (
                      fileTypes &&
                      Object.keys(fileTypes)
                        .filter((fileType) => fileType !== "FILLOUT_AML_KYC_FORM")
                        .map((value) => (
                          <option
                            key={value}
                            value={value}
                            disabled={disableFileTypeOption(values, value)}
                          >
                            {intl.formatMessage({ id: fileTypes[value] })}
                          </option>
                        ))
                    )}
                  </Select>
                </div>
              </div>
              {canEditSubcontractorFields(values) && (
                <>
                  <div className="form-group row">
                    <div className="col-lg-12">
                      {projectsListLoading && (
                        <Spinner
                          animation="border"
                          variant="primary"
                          className="position-absolute"
                          style={{ top: "30px", left: "20px" }}
                        />
                      )}
                      <Select
                        data-cy="subcontractor-file-project-form"
                        name="projectId"
                        label="Project"
                        onChange={(e) => onProjectChange(e, handleChange, setFieldValue)}
                        value={values.projectId}
                        disabled={file.id && file.reInvoiceFile?.leadFileId}
                      >
                        {!isFinanceFile(values.fileType) && <option value={"NONE"}>-</option>}
                        {projects.map((project, index) => (
                          <option key={index} value={project.id}>
                            {project.name}
                          </option>
                        ))}
                      </Select>
                    </div>
                  </div>
                  {values.fileType === "INVOICE" && (
                    <div className="row mb-4">
                      <div className="col-sm-6">
                        <Select
                          name="invoiceStatus"
                          data-cy="select-invoice-status"
                          label={intl.formatMessage({
                            id: "COMMON.STATUS",
                          })}
                          customFeedbackLabel=""
                        >
                          {Object.entries(INVOICE_STATUS).map(([key, val]) => (
                            <option key={key} value={key}>
                              {intl.formatMessage({ id: val })}
                            </option>
                          ))}
                        </Select>
                      </div>
                      {values?.invoiceStatus !== "PAID" && (
                        <div className="col-sm-6">
                          <label>
                            <FormattedMessage id="TASK.LABEL.DATE.DUE" />
                          </label>
                          <div className="position-relative" data-cy="datepicker-due-date">
                            <Field
                              name="dueDate"
                              showTimeSelect={false}
                              placeholderText={DateUtils.format(new Date(), intl, false)}
                              component={DatePickerField}
                              dateFormat="dd/MM/yyyy"
                              onChange={(e) => {
                                const x = new Date().getTimezoneOffset() * 60000;
                                const localISOTime = new Date(e.getTime() - x)
                                  .toISOString()
                                  .slice(0, -1);
                                return setFieldValue("dueDate", localISOTime);
                              }}
                            />
                            {values.dueDate && (
                              <span
                                className="position-absolute right-0 mr-4 cursor-pointer"
                                style={{ top: "50%", transform: "translateY(-50%)" }}
                                onClick={() => {
                                  setFieldValue("dueDate", "");
                                }}
                              >
                                <i className="fas fa-times" />
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                      {values?.invoiceStatus === "PARTIALLY_PAID" && (
                        <div className="col-sm-4 mt-4">
                          <label>
                            <FormattedMessage id="TASK.LABEL.PAID_TO_DATE" />
                          </label>
                          <NumberInput
                            suffix={SUFFIX_EURO_CURRENCY}
                            name="amountPaidToDate"
                            data-cy="input-amount-paid-to-date"
                            className="form-control text-right"
                            value={values.amountPaidToDate}
                            onValueChange={(e) => {
                              handleChange({
                                target: {
                                  name: "amountPaidToDate",
                                  value: e.floatValue ?? 0,
                                },
                              });
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  {project && isFinanceFile(values.fileType) && (
                    <>
                      {project.selectedBudget ? (
                        <label>
                          <FormattedMessage id="BUDGET.ACTION.CHOOSE.CATEGORY_OR_LINE" />
                        </label>
                      ) : (
                        isBudgetAvailable && (
                          <label className="text-warning">
                            <FormattedMessage id="BUDGET.NOT.IN.PROJECT" />{" "}
                            <Link to={`/budgets/new?projectId=${project.id}`}>
                              <FormattedMessage id="BUDGET.ACTION.CREATE.BUDGET" />
                            </Link>{" "}
                            <FormattedMessage id="BUDGET.ACTION.OR.FILL.OUT.INFORMATION" />
                          </label>
                        )
                      )}
                      <UserFileFinanceCategories {...{ budget, isBudgetAvailable, file }} />
                    </>
                  )}
                </>
              )}
              {uploadingFile && (
                <div className="form-group row">
                  <div className="col">
                    {actionsLoading && !!values.file ? (
                      <div className="upload-file-container upload-file-container--disabled">
                        <div className="d-flex justify-content-center align-middle">
                          <div className="spinner-grow text-primary mr-4" />
                          <div>
                            <FormattedMessage id="FILE.ACTION.UPLOADING" />
                          </div>
                        </div>
                      </div>
                    ) : selectedFileType === "ID" ? (
                      <>
                        <div className="row">
                          <div className="col mb-2">
                            <Checkbox
                              name={"rectoVerso"}
                              label={"FILE.FRONT.BACK"}
                              onChange={() => setRectoVerso(!rectoVerso)}
                              checked={rectoVerso}
                              data-cy="checkbox-recto-verso"
                            />
                          </div>
                        </div>
                        {rectoVerso ? (
                          <FileDropzone values={values} setFieldValue={setFieldValue} />
                        ) : (
                          <div className="row d-flex justify-content-around">
                            <div className="col-md-6 mb-5 mb-md-0">
                              <FileDropzone
                                values={values}
                                setFieldValue={setFieldValue}
                                textField="FILE.FRONT"
                              />
                            </div>
                            <div className="col-md-6 mb-5 mb-md-0">
                              <FileDropzone
                                values={values}
                                setFieldValue={setFieldValue}
                                textField="FILE.BACK"
                                inputName="fileBack"
                              />
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <FileDropzone
                        values={values}
                        setFieldValue={setFieldValue}
                        acceptedTypes={
                          isFinanceFile(selectedFileType) && values.userType === SUBCONTRACTOR
                            ? ["image/png", "image/jpeg", "application/pdf"]
                            : undefined
                        }
                      />
                    )}
                  </div>
                </div>
              )}
              {canReadAll(groups, session, TAG) && file.id && (
                <div className="form-group row">
                  <div className="col-lg-12" data-cy="select-custom-tags">
                    <TagManager
                      displayLogo={false}
                      fullWidth
                      relatedTo={`${FILE}#${file.id}`}
                      readOnly={readOnly}
                    />
                  </div>
                </div>
              )}
              {canReInvoice(values) && project && budget && (
                <UserFileFinanceReInvoice
                  projectId={project.id}
                  budgetInstalments={budget.budgetInstalments}
                  file={file}
                />
              )}
              {canEditFinanceCategories(values) && canEdit(groups, session, "BUDGET") && (
                <Checkbox
                  name="selectedForFinance"
                  label="FILE.ACTION.SELECT_FOR_FINANCE_ANALYSIS"
                  customClasses="mb-2"
                />
              )}
              {canReadAllUserDocument(groups, session, entityForEdit) && !isRequest && (
                <>
                  <TitleWithGuideLink
                    titleMessageId="COMMON.CONFIDENTIAL.DOCUMENT"
                    descriptionMessageId="FILE.CONFIDENTIAL.DOCUMENT.DESCRIPTION"
                    guideLink={fm("FILE.CONFIDENTIAL.DOCUMENT.LINK")}
                  />
                  <Checkbox name="isConfidential" label="COMMON.CONFIDENTIAL.DOCUMENT" />
                </>
              )}
              {user &&
                !values.isConfidential &&
                !readOnly &&
                file.fileType !== "FILLOUT_AML_KYC_FORM" &&
                userId !== session.id && (
                  <div className="form-group row mt-4">
                    <div className="col-lg-12">
                      <Notification
                        userType={
                          user.userTypes.length === 0 || user.userTypes.includes(UserType.USER)
                            ? UserType.USER
                            : user.userTypes[0]
                        }
                        notifyMethods={values.notifyUser}
                        setNotifyMethods={(options) =>
                          handleChange({
                            target: {
                              name: "notifyUser",
                              value: options,
                            },
                          })
                        }
                      />
                    </div>
                  </div>
                )}
              <div className="separator separator-solid separator-border-2 my-4"></div>
              <LinkedFiles activeFile={file} />
            </Form>
          </Modal.Body>
          <Modal.Footer className={"align-items-end"}>
            <div ref={footerRef} className={"flex-grow-1"}></div>
            <div className="ml-auto">
              <button
                type="button"
                data-cy="button-cancel"
                disabled={actionsLoading}
                onClick={onHide}
                className="btn btn-light btn-elevate mr-2"
              >
                <FormattedMessage id="COMMON.ACTION.CANCEL" />
              </button>
              <button
                disabled={actionsLoading}
                type="submit"
                onClick={handleSubmit}
                className="btn btn-primary btn-elevate"
                data-cy="button-submit"
              >
                {isRequest ? (
                  <FormattedMessage id="COMMON.ACTION.REQUEST" />
                ) : (
                  <FormattedMessage id="COMMON.ACTION.SAVE" />
                )}
              </button>
            </div>
          </Modal.Footer>
        </>
      )}
    </Formik>
  );
}
